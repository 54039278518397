



































import { Component, Vue } from 'vue-property-decorator';
import { H3Empty, H3Carousel, H3Icon } from '@authine/awesome-ui';

import { WorkPlatformService } from '@WorkPlatform/api/index';

import { namespace } from 'vuex-class';

const workPlatformModule = namespace('workPlatform');

@Component({
  name: 'app-list',
  components: {
    AEmpty: H3Empty,
    ACarousel: H3Carousel,
    AIcon: H3Icon,
    AppPage: () => import('./app-page.vue'),
  },
})
export default class ComponentName extends Vue {
  @workPlatformModule.Action('setOlineApps') setOlineApps: any;

  @workPlatformModule.State('recentApps') recentApps: any;

  pagination: any = {
    // 总条数
    total: 0,
    // 当前页
    page: 1,
    // 总页码
    pageTotal: 0,
  };

  /**
   * app数据
   */
  apps: any = {};

  emptyImgUrl = require('@/assets/images/empty.svg');

  onlineAppList: any[] = [];

  /**
   * 搜索参数
   */
  params: any = {
    keyword: '',
    page: 1,
    size: 999,
  };

  onlineApps: any[] = [];

  async created () { // 重登陆页切换组织后newPage打开
    await this.getAppList({});
    this.setOlineApps(this.onlineAppList);
  }

  /**
   * 搜索
   */
  search (searchKey: string) {
    const params: any = {
      ...this.params,
      page: 1,
      keyword: searchKey,
    };
    this.params = params;
    this.apps = {};

    this.getAppList(params);
  }

  /**
   * 获取应用列表
   */
  async getAppList (query: any) {
    const params: any = {
      ...this.params,
      ...query,
    };
    this.params = params;

    const res: any = await WorkPlatformService.getAppList(params);

    if (res.success) {
      const total: number = res.total;
      const size: number | undefined = this.params.size;
      this.onlineAppList = res.data;
      if (!query.keyword) {
        this.$emit('allApps', this.onlineAppList); // 首次进来时获取appList, 给常用应用使用
      }
      let pageTotal: number = 0;
      if (total && size) {
        pageTotal = Math.ceil(total / size);
      }
      this.pagination.total = total || 0;
      this.pagination.pageTotal = pageTotal || 0;

      // 设置轮播页
      if (Object.keys(this.apps).length === 0 && pageTotal > 0) {
        const obj: any = {};
        for (let index = 0; index < pageTotal; index++) {
          obj[index + 1] = [];
        }
        this.apps = obj;
      }

      // 设置轮播页数据
      const data: any[] = res.data;

      if (Array.isArray(data) && data.length) {
        const page: number | undefined = this.params.page;
        if (!page) return;
        this.$set(this.apps, page, data);
      }
    } else {
      this.apps = {};
    }
  }

  /**
   * 轮播切换
   */
  slideBeforeChange (from, to) {
    const page: number = to + 1;
    if (this.pagination.pageTotal < page || to === -1) {
      return;
    }
    if (this.apps[page] && this.apps[page].length) {
      return;
    }
    const params: any = {
      page: page,
    };
    this.getAppList(params);
  }
}
